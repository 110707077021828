import { Link } from "react-router-dom";

export default function Home() {

    return (
        <div className="home-page">

            {
                // Home banner
            }
            <section className="img-banner">
                <img src={require("../assets/jesse-collins-kfqZbKHV8dQ-unsplash.jpg")} alt="MFC Engineering Service Home Banner" />
            </section>


            {
                // About section
            }
            <section className="default-section">
                <aside className="section-entry">
                    <h2> About MFC</h2>
                    <p>
                        MFC Engineering Service is a newly established civil engineering consulting company located in North Lakes,
                        Queensland.
                        We are skilled in Highways, Rail, Bridge, LNG, Materials Testing, Quality Auditing, and Bulk Earthworks.
                        Our history in the civil engineering industry is backed with a background in strong construction and project
                        management and
                        Bachelor of Applied Science (B.A.Sc.) focused in Civil Engineering from the Polytechnic University of the
                        Philippines.
                    </p>
                </aside>
                <img src={require("../assets/latest-project01.jpg")} alt="Latest project" className="img-round_corners section-img" />
            </section>

            {
                // Projects section
            }
            <section className="img-banner-alt align-right projects-banner">
                <aside className="section-card">
                    <h2>Projects</h2>
                    <p>
                        MFC Engineering Service combines decades worth of experience in the civil engineering industry
                        to deliver innovative and cost-effective solutions to your project.
                    </p>
                    <Link to="/projects" className="nav-btn">Check out projects</Link>
                </aside>
            </section>

            {
                // Services section
            }
            <section className="center-section">
                <h2 className="center-heading">Services</h2>

                <ul className="card-list">
                    <li>
                        <img src={require("../assets/1613766849625.jpg")} alt="" className="service-img" />
                        <h3>Bridge & Highways</h3>
                    </li>

                    <li>
                        <img src={require("../assets/1613768422810.jpg")} alt="" className="service-img" />
                        <h3>Rail</h3>
                    </li>

                    <li>
                        <img src={require("../assets/1613767082032.jpg")} alt="" className="service-img" />
                        <h3>LNG</h3>
                    </li>

                    <li>
                        <img src={require("../assets/pipes-sorted-on-construction-site.jpg")} alt="" className="service-img" />
                        <h3>Materials Testing</h3>
                    </li>

                    <li>
                        <img src={require("../assets/site-audit.jpg")} alt="" className="service-img" />
                        <h3>Quality Auditing</h3>
                    </li>

                    <li>
                        <img src={require("../assets/1613769093620.jpg")} alt="" className="service-img" />
                        <h3>Bulk Earthworks</h3>
                    </li>

                </ul>
            </section>
        </div >
    );
}