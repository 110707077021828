import { useState } from "react";
import jsPDF from "jspdf";

export default function Diary() {
    // Initialise projectData and prefill input values

    const issuesDisp = ["Quality", "WH&S", "Environmental", "Traffic", "Delays & Inneficiencies",
        "Community", "Water & Gas Valve", "Fire Hydrants", "Sewer & Stormwater Manholes", "Stakeholder", "Accidents"];

    const issuesFunc = ["quality", "whs", "environmental", "traffic", "delays",
        "community", "valves", "fireHyd", "manholes", "stakeholder", "accidents"];

    const [projectData, setProjectData] = useState(loadInputs());

    const data = projectData.issues;
    const issues_rows = [data.quality, data.whs, data.environmental, data.traffic, data.delays,
    data.community, data.valves, data.fireHyd, data.manholes, data.stakeholder, data.accidents];

    // Load input values from localStorage OR load an empty template
    function loadInputs() {
        const result = {
            title: "", no: "", inspector: "", date: "",
            visits: [{ id: 0, arrival: "", departure: "", time: "" }],
            bomRec: "",
            rainGauge: "",
            wNotes: "",
            siteCond: "",
            idCrit: "",
            idNoncrit: "",
            dailyWorkRecords: [{
                dwrID: 0, pStartDate: "", aStartDate: "", compPer: "",
                pFinDate: "", aFinDate: "", lotNo: "", hpwpRelease: "Y", dwrDesc: "",
                equipment: [{ plant: "", no: "", hrs: "" }],
                personnel: [{ desc: "", no: "", hrs: "" }],
                imgLoc: "", img: ""
            }],
            mats: [{ material: "", supplier: "", desc: "", quantity: "", defects: "" }],
            issues: {},
            genComm: ""
        };

        issuesFunc.forEach((issue, index) => {
            result.issues[issue] = {
                issue: "",
                farNo: "",
                noServices: "",
                desc: ""
            }
        });

        return result;
    }

    // Handle value changes on form inputs and add them to projectData object
    function handleChange(event) {
        setProjectData({ ...projectData, [event.target.name]: event.target.value });
    }

    // Prevent form from submitting and refreshing the page, validate inputs
    function handleSubmit(event) {
        // Prevent form from refreshing the page
        event.preventDefault();

        // Validate inputs 

    }

    function handleIssue(event, issueName) {
        let records = projectData.issues;

        if (event.target.name === "issue") {
            records[issueName]["issue"] = event.target.checked;
        }
        else {
            records[issueName][event.target.name] = event.target.value;

        }

        setProjectData({ ...projectData, issues: records });
    }

    function addVisit() {
        // count the size of visits
        let visitsSize = Object.keys(projectData.visits).length;

        let maxVisits = 0;

        // count the id of the last entry in the array
        if (visitsSize > 0) {
            maxVisits = projectData.visits[visitsSize - 1].id;
        }

        // add empty visit to projectData.visits
        let visits = projectData.visits;

        visits.push({
            id: maxVisits + 1,
            arrival: "",
            departure: "",
            time: ""
        });

        setProjectData({ ...projectData, visits: visits });
    }

    function delVisit(key) {
        // delete visit to projectData.visits
        let visits = projectData.visits;

        visits.splice(key, 1);

        setProjectData({ ...projectData, visits: visits });
    }

    // set json object for visits
    function handleVisit(key, element) {
        let visits = projectData.visits;
        visits[key][element.target.name] = element.target.value;

        setProjectData({ ...projectData, visits: visits });
    }

    function addWork() {
        // count the size of visits
        let workSize = Object.keys(projectData.dailyWorkRecords).length;

        let maxWorks = 0;

        // count the id of the last entry in the array
        if (workSize > 0) {
            maxWorks = projectData.dailyWorkRecords[workSize - 1].dwrID;
        }

        // add empty visit to projectData.visits
        let records = projectData.dailyWorkRecords;

        records.push({
            dwrID: maxWorks + 1,
            pStartDate: "",
            aStartDate: "",
            compPer: "",
            pFinDate: "",
            aFinDate: "",
            lotNo: "",
            hpwpRelease: "",
            dwrDesc: "",
            equipment: [{
                plant: "",
                no: "",
                hrs: ""
            }],
            personnel: [{
                desc: "",
                no: "",
                hrs: ""
            }],
            imgLoc: "",
            img: ""
        });

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    function delWork(key) {
        let records = projectData.dailyWorkRecords;

        records.splice(key, 1);

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    // set json object for dailyWorkRecords
    function handleWork(key, element) {
        let records = projectData.dailyWorkRecords;

        records[key][element.target.name] = element.target.value;

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    function handleImg(key, element) {
        let records = projectData.dailyWorkRecords;

        let file = new FileReader()

        file.onloadend = function () {
            records[key][element.target.name] = file.result;
        }

        file.readAsDataURL(element.target.files[0]);

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }


    function addPlant(key) {
        // add empty plant
        let records = projectData.dailyWorkRecords;

        records[key].equipment.push({
            plant: "",
            no: "",
            hrs: ""
        });

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    function delPlant(key, index) {
        let records = projectData.dailyWorkRecords;

        records[key].equipment.splice(index, 1);

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    // set json object for dailyWorkRecords
    function handlePlant(key, element, index) {
        let records = projectData.dailyWorkRecords;
        records[key].equipment[index][element.target.name] = element.target.value;

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    function addPersnl(key) {
        // add empty plant
        let records = projectData.dailyWorkRecords;

        records[key].personnel.push({
            desc: "",
            no: "",
            hrs: ""
        });

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    function delPersnl(key, index) {
        let records = projectData.dailyWorkRecords;

        records[key].personnel.splice(index, 1);

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    // set json object for dailyWorkRecords
    function handlePersnl(key, element, index) {
        let records = projectData.dailyWorkRecords;
        records[key].personnel[index][element.target.name] = element.target.value;

        setProjectData({ ...projectData, dailyWorkRecords: records });
    }

    function addMat() {
        // add empty visit to projectData.visits
        let records = projectData.mats;

        records.push({
            material: "",
            supplier: "",
            desc: "",
            quantity: "",
            defects: ""
        });

        setProjectData({ ...projectData, mats: records });
    }

    function delMat(key) {
        let records = projectData.mats;

        records.splice(key, 1);

        setProjectData({ ...projectData, mats: records });
    }

    // set json object for dailyWorkRecords
    function handleMat(key, element) {
        let records = projectData.mats;
        records[key][element.target.name] = element.target.value;

        setProjectData({ ...projectData, mats: records });
    }

    // Exports projectData into a PDF blob/format
    function exportPDF() {
        // Create PDF File
        const doc = new jsPDF("landscape", "px", "a4");

        // individual margins for vertical and horizontal margins
        const vertMrgn = 25;
        const horzMrgn = 25;

        // total printable area
        const docHeight = doc.internal.pageSize.getHeight() - (vertMrgn * 2);
        //const docWidth = doc.internal.pageSize.getWidth() - (horzMrgn * 2);

        const cellHeight = 15;
        const cellFontSize = 10;

        // starting point
        let startY = vertMrgn;
        let startX = horzMrgn;
        let savedX = startX;
        let savedY = startY;

        function resetStartX() {
            startX = horzMrgn;
            return startX;
        }

        function resetStartY() {
            startY = vertMrgn;
            return startY;
        }

        // moves the cursor startX based on size then return current cursor position
        function StartX(size) {
            startX += size;
            return startX;
        }

        // moves the cursor startX based on size then return current cursor position
        function StartY(size = cellHeight) {
            startY += size;
            return startY;
        }

        // save the current position of the cursor in the x-axis
        function saveX(size) {
            savedX = size;
            return savedX;
        }

        // save the current position of the cursor in the y-axis
        function saveY(size) {
            savedY = size;
            return savedY;
        }

        function switchX(size = 0) {
            const temp = startX;
            startX = savedX + size;
            savedX = temp;

            return startX;
        }

        function switchY(size = 0) {
            const temp = startY;
            startY = savedY + size;
            savedY = temp;

            return startY;
        }

        // add a cell with text or empty box
        function addCell(text = "", nextDirection = undefined,
            width = 0, height = cellHeight,
            x = startX, y = startY) {

            doc.cell(x, y, width, height, text === "" ? " " : text);

            switch (nextDirection) {
                case "N":
                    StartY(-height);
                    break;
                case "E":
                    StartX(width);
                    break;
                case "S":
                    StartY(height);
                    break;
                case "W":
                    StartX(-width);
                    break;
                default:
                    break;
            }

        }

        function heading(text = "", fontSize, x = startX, y = startY) {
            doc.setFontSize(fontSize);
            doc.text(text, x, y);
            doc.setFontSize(cellFontSize);
        }

        // project details
        heading("Daily Diary", 20);

        doc.addImage(require("../assets/bcc.jpg"), "JPEG", doc.internal.pageSize.getWidth() - 30 - 468.75/5, 15, 468.75/5, 264/5);
        
        doc.setFontSize(9);
        doc.text("Brisbane Infrastructure Division", doc.internal.pageSize.getWidth() - 30 - 468.75/5 - 0, 264/5 - 7 + (cellHeight * 2));
        doc.text("City Projects Office",             doc.internal.pageSize.getWidth() - 30 - 468.75/5 + 20, 264/5 - 7 + (cellHeight * 2.5));
        doc.text("Project Management Branch",        doc.internal.pageSize.getWidth() - 30 - 468.75/5 + 5, 264/5 - 7 + (cellHeight * 3));
        doc.text("Civil & Transport Program",        doc.internal.pageSize.getWidth() - 30 - 468.75/5 + 7, 264/5 - 7 + (cellHeight * 3.5));
        doc.setFontSize(cellFontSize);

        addCell("Project Title:", "E", 50, undefined, resetStartX(), StartY(10));
        addCell(projectData.title, "E", 100);

        addCell("Project No.:", "E", 50);
        addCell(projectData.no, "E", 50);

        addCell("Inspector", "E", 50, undefined, resetStartX(), StartY());
        addCell(projectData.inspector, "E", 100);

        addCell("Date:", "E", 50);
        addCell(projectData.date, "E", 50);

        // site visits
        heading("Site Visits", 12, resetStartX(), StartY(cellHeight * 2));

        addCell("Visit No.", "E", 50, undefined, resetStartX(), StartY(5));
        addCell("Arrival", "E", 50);
        addCell("Departure", "E", 50);
        addCell("Time on Site (Hours)", "E", 100);

        Object.keys(projectData.visits).forEach((key, index) => {
            addCell((index + 1).toString(), "E", 50, undefined, resetStartX(), StartY());
            addCell(projectData.visits[key].arrival.toString(), "E", 50);
            addCell(projectData.visits[key].departure.toString(), "E", 50);
            addCell(projectData.visits[key].time.toString(), "E", 100);
        });

        // scheduled work
        heading("Scheduled Work", 12, saveX(resetStartX()), saveY(StartY(cellHeight * 2)));

        addCell("Unique ID Nos - Critical Path", "E", 150, undefined, resetStartX(), StartY(5));
        addCell(projectData.idCrit, "S", 50);
        addCell("Unique ID Nos - Noncritical", "E", 150, undefined, resetStartX());
        addCell(projectData.idNoncrit, undefined, 50);

        // weather
        heading("Overnight Rainfall to 9:00am", 12, switchX(280), switchY());

        addCell("BOM Record", "E", 50, undefined, undefined, StartY(5));
        addCell(`${projectData.bomRec}mm`, "E", 50);

        addCell("Project Rain Gauge", "E", 100);
        addCell(`${projectData.rainGauge}mm`, "S", 50);

        addCell("Weather Notes", "E", 100, undefined, startX -= 200);
        addCell(projectData.wNotes, "S", 150);

        addCell("General Site Condition", "E", 100, undefined, startX -= 100);
        addCell(projectData.siteCond, undefined, 150);

        // daily works records
        heading("Daily Works Record", 12, resetStartX(), StartY(cellHeight * 2));

        addCell("Unique ID", "E", 30, cellHeight * 2, resetStartX(), StartY(5));
        addCell("", "E", 150, cellHeight * 2);
        addCell("Description, Location, Output/Work Completed", "E", 100, cellHeight * 2);
        addCell("Plant/Equipment", "S", 150);
        addCell("Plant", "E", 100);
        addCell("No.", "E", 25);
        addCell("Hrs", "E", 25);

        addCell("Personnel", "S", 150, undefined, undefined, StartY(-cellHeight));
        addCell("Description", "E", 100);
        addCell("No.", "E", 25);
        addCell("Hrs", "E", 25);

        let maxHeight = 0

        Object.keys(projectData.dailyWorkRecords).forEach((key, index) => {

            maxHeight = cellHeight * 8;

            if (maxHeight <= (projectData.dailyWorkRecords[index].equipment.length) * cellHeight) {
                maxHeight = (projectData.dailyWorkRecords[index].equipment.length) * cellHeight;
            }

            else if (maxHeight <= (projectData.dailyWorkRecords[index].personnel.length) * cellHeight) {
                maxHeight = (projectData.dailyWorkRecords[index].personnel.length) * cellHeight;
            }

            // determine if a new page needs to be added
            if (startY + maxHeight >= docHeight) {
                doc.addPage();
                resetStartY();
            }

            let maxX = startY + maxHeight;

            addCell("", undefined, 580, maxHeight, resetStartX(), StartY());
            addCell(projectData.dailyWorkRecords[key].dwrID.toString(), "E", 30, maxHeight);

            saveX(startX); saveY(startY);

            let rows = [
                "Program Start Date", "Actual Start Date", "% Comp to Date", "Program Finish Date",
                "Actual Finish Date", "Lot No.", "HP/WP Release", "Image Location/ Chainage"
            ];

            rows.forEach((row) => {
                addCell(row, "S", 100);
            });

            switchX(100); switchY();
            saveX(startX); saveY(startY);

            const rowValues = [
                projectData.dailyWorkRecords[key].pStartDate.toString(),
                projectData.dailyWorkRecords[key].aStartDate.toString(),
                projectData.dailyWorkRecords[key].compPer.toString(),
                projectData.dailyWorkRecords[key].pFinDate.toString(),
                projectData.dailyWorkRecords[key].aFinDate.toString(),
                projectData.dailyWorkRecords[key].lotNo.toString(),
                projectData.dailyWorkRecords[key].hpwpRelease.toString()
            ];

            rowValues.forEach((row) => {
                addCell(row, "S", 50);
            });

            addCell(projectData.dailyWorkRecords[key].imgLoc.toString(), "S", 150);

            switchX(50); switchY();
            saveX(startX); saveY(startY);

            addCell(projectData.dailyWorkRecords[key].dwrDesc.toString(), "E", 100, cellHeight * 7);

            switchX(100); switchY();
            saveX(startX); saveY(startY);

            addCell("", undefined, 150, maxHeight);

            Object.keys(projectData.dailyWorkRecords[key].equipment).forEach((row) => {
                addCell(projectData.dailyWorkRecords[key].equipment[row].plant.toString(), "E", 100);
                addCell(projectData.dailyWorkRecords[key].equipment[row].no.toString(), "E", 25);
                addCell(projectData.dailyWorkRecords[key].equipment[row].hrs.toString(), "E", 25);

                switchX();
                saveX(startX);
                StartY();
            });

            switchX(150); switchY();
            saveX(startX); saveY(startY);

            addCell("", undefined, 150, maxHeight);

            Object.keys(projectData.dailyWorkRecords[key].personnel).forEach((row) => {
                addCell(projectData.dailyWorkRecords[key].personnel[row].desc.toString(), "E", 100);
                addCell(projectData.dailyWorkRecords[key].personnel[row].no.toString(), "E", 25);
                addCell(projectData.dailyWorkRecords[key].personnel[row].hrs.toString(), "E", 25);

                switchX();
                saveX(startX);
                StartY();
            });

            startY = maxX;
        });

        // material deliveries
        heading("Material Deliveries", 12, resetStartX(), StartY(cellHeight * 2));
        StartY(5);

        let rows = [
            "Material", "Supplier", "Description",
            "Quantity", "Defect Notes & Waste Quantity"
        ];

        let maxWidth = 580 / rows.length;

        rows.forEach((row) => {
            addCell(row, "E", maxWidth);
        });

        Object.keys(projectData.mats).forEach((key) => {

            maxHeight = cellHeight;

            // determine if a new page needs to be added
            if (startY + maxHeight >= docHeight) {
                doc.addPage();
                resetStartY();
            }

            addCell(projectData.mats[key].material.toString(), "E", maxWidth, undefined, resetStartX(), StartY());
            addCell(projectData.mats[key].supplier.toString(), "E", maxWidth);
            addCell(projectData.mats[key].desc.toString(), "E", maxWidth);
            addCell(projectData.mats[key].quantity.toString(), "E", maxWidth);
            addCell(projectData.mats[key].defects.toString(), undefined, maxWidth);
        });

        // generic/system issues
        maxHeight = cellHeight * 12;

        // determine if a new page needs to be added
        if (startY + maxHeight >= docHeight) {
            doc.addPage();
            resetStartY();

            heading("Generic/System Issues", 12, resetStartX());
        }
        else {
            heading("Generic/System Issues", 12, resetStartX(), StartY(cellHeight * 2));
        }

        StartY(5);

        rows = [
            "Item", "Issue? (Y|N)", "FAR Number", "No. of Services",
            "Issues Description/Item Comments (both positive and negative)"
        ];

        let widths = [110, 50, 100, 100, 580 - (110 + 50 + 200)]

        maxWidth = 580 / rows.length;

        rows.forEach((row, index) => {
            addCell(row, "E", widths[index]);
        });

        resetStartX();
        StartY();

        issuesDisp.forEach((row, index) => {
            addCell(row, "E", widths[0]);
            addCell((issues_rows[index].issue.toString() === "true") ? "Y" : "N", "E", widths[1]);
            addCell(issues_rows[index].farNo.toString(), "E", widths[2]);
            addCell(issues_rows[index].noServices.toString(), "E", widths[3]);
            addCell(issues_rows[index].desc.toString(), "S", widths[4]);
            resetStartX();
        });

        // general comments
        maxHeight = cellHeight * 4;

        // determine if a new page needs to be added
        if (startY + maxHeight >= docHeight) {
            doc.addPage();
            resetStartY();

            heading("General Comments", 12, resetStartX());
        }
        else {
            heading("General Comments", 12, resetStartX(), StartY());
        }

        addCell(projectData.genComm.toString(), "S", 580, cellHeight * 3, startX, StartY(5));

        // images
        maxHeight = 144 + cellHeight;

        // determine if a new page needs to be added
        if (startY + maxHeight >= docHeight) {
            doc.addPage();
            resetStartY();

            heading("Images", 12, resetStartX());
        }
        else {
            heading("Images", 12, resetStartX(), StartY());
        }

        StartY(5);

        addCell("Unique ID", "E", 50);
        addCell("Image", "S", 256);

        resetStartX();

        Object.keys(projectData.dailyWorkRecords).forEach((key) => {
            if (startY + maxHeight >= docHeight) {
                doc.addPage();
                resetStartY();
            }

            addCell(projectData.dailyWorkRecords[key].dwrID.toString(), "E", 50, 144);
            addCell("", undefined, 256, 144);

            if (projectData.dailyWorkRecords[key].img) {
                const img = new Image();
                img.src = projectData.dailyWorkRecords[key].img;

                const imgType = /(?<=^data:image\/)(.+)(?=;base64,)/.exec(projectData.dailyWorkRecords[key].img);

                doc.addImage(projectData.dailyWorkRecords[key].img.toString(), imgType[0], startX, startY, 256, 144);

            }

            StartY(144);
            resetStartX();
        });

        doc.save(`${projectData.title}.pdf`);
    };

    return (
        <div className="diary-page">

            <section className="default-section">

                <aside className="section-entry">
                    <h2>Daily Report Tool</h2>
                    <form className="reportForm tabs" method="post" onSubmit={e => handleSubmit(e)}>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd1" name="rd1" />
                            <label className="tab-label" htmlFor="rd1"><h3>Project Details</h3></label>

                            <div className="tab-content">
                                <label htmlFor="title">Project Title</label>
                                <input type="text" name="title" value={projectData.title} onChange={e => handleChange(e)} />

                                <label htmlFor="no">Project No.</label>
                                <input type="text" name="no" value={projectData.no} onChange={e => handleChange(e)} />

                                <label htmlFor="inspector">Project Inspector</label>
                                <input type="text" name="inspector" value={projectData.inspector} onChange={e => handleChange(e)} />

                                <label htmlFor="date">Project Date</label>
                                <input type="date" name="date" value={projectData.date} onChange={e => handleChange(e)} />
                            </div>
                        </section>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd2" name="rd2" />
                            <label className="tab-label" htmlFor="rd2"><h3>Site Visits</h3></label>

                            <div className="tab-content">
                                {
                                    // build an input for every visit
                                    // "key" are properties projectData.visits, e.g. "visit_1"
                                    Object.keys(projectData.visits).map((key, index) => (

                                        <fieldset className="form-flex" key={key}>
                                            <button type="button" className="delBtn" onClick={() => delVisit(key)}>❌</button>
                                            <h4>Visit {index + 1}</h4>

                                            <label>Arrival</label>
                                            <input type="time" name="arrival" value={projectData.visits[key].arrival} onChange={e => handleVisit(key, e)} />

                                            <label>Departure</label>
                                            <input type="time" name="departure" value={projectData.visits[key].departure} onChange={e => handleVisit(key, e)} />

                                            <label>Time on Site (Hours:Minutes)</label>
                                            <input type="text" name="time" value={projectData.visits[key].time} onChange={e => handleVisit(key, e)} />

                                        </fieldset>
                                    ))
                                }

                                <button type="button" onClick={() => addVisit()}>Add Visit</button>
                            </div>
                        </section>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd3" name="rd3" />
                            <label className="tab-label" htmlFor="rd3"><h3>Weather (Overnight Rainfall to 9:00am)</h3></label>

                            <div className="tab-content">

                                <label htmlFor="bomRec">BOM Record (mm)</label>
                                <input type="text" name="bomRec" value={projectData.bomRec} onChange={e => handleChange(e)} />

                                <label htmlFor="rainGauge">Project Rain Gauge (mm)</label>
                                <input type="text" name="rainGauge" value={projectData.rainGauge} onChange={e => handleChange(e)} />

                                <label htmlFor="wNotes">Weather Notes</label>
                                <input type="text" name="wNotes" value={projectData.wNotes} onChange={e => handleChange(e)} />

                                <label htmlFor="siteCond">General Site Condition</label>
                                <input type="text" name="siteCond" value={projectData.siteCond} onChange={e => handleChange(e)} />
                            </div>
                        </section>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd4" name="rd4" />
                            <label className="tab-label" htmlFor="rd4"><h3>Scheduled Work</h3></label>

                            <div className="tab-content">
                                <label htmlFor="idCrit">Unique ID Nos - Critical Path</label>
                                <input type="text" name="idCrit" value={projectData.idCrit} onChange={e => handleChange(e)} />

                                <label htmlFor="idNoncrit">Unique ID Nos - Noncritical</label>
                                <input type="text" name="idNoncrit" value={projectData.idNoncrit} onChange={e => handleChange(e)} />
                            </div>
                        </section>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd5" name="rd5" />
                            <label className="tab-label" htmlFor="rd5"><h3>Daily Work Records</h3></label>

                            <div className="tab-content">
                                {
                                    Object.keys(projectData.dailyWorkRecords).map((key, index) => (
                                        <div className="form-flex" key={key}>
                                            <button type="button" className="delBtn" onClick={() => delWork(key)}>❌</button>
                                            <label htmlFor="dwrID">Unique ID</label>
                                            <input type="text" name="dwrID" value={projectData.dailyWorkRecords[key].dwrID} onChange={e => handleWork(key, e)} />

                                            <div className="form-flex-alt-spaceBet">
                                                <div className="form-flex">
                                                    <label htmlFor="pStartDate">Program Start Date</label>
                                                    <input type="date" name="pStartDate" value={projectData.dailyWorkRecords[key].pStartDate} onChange={e => handleWork(key, e)} />
                                                </div>

                                                <div className="form-flex">
                                                    <label htmlFor="aStartDate">Actual Start Date</label>
                                                    <input type="date" name="aStartDate" value={projectData.dailyWorkRecords[key].aStartDate} onChange={e => handleWork(key, e)} />
                                                </div>
                                            </div>

                                            <label htmlFor="compPer">Completion to Date({projectData.dailyWorkRecords[key].compPer === "" ? 0 : projectData.dailyWorkRecords[key].compPer}%)</label>
                                            <input type="range" min="0" max="100" name="compPer" value={projectData.dailyWorkRecords[key].compPer === "" ? 0 : projectData.dailyWorkRecords[key].compPer} onChange={e => handleWork(key, e)} />

                                            <div className="form-flex-alt-spaceBet">
                                                <div className="form-flex">
                                                    <label htmlFor="pFinDate">Program Finish Date</label>
                                                    <input type="date" name="pFinDate" value={projectData.dailyWorkRecords[key].pFinDate} onChange={e => handleWork(key, e)} />
                                                </div>

                                                <div className="form-flex">
                                                    <label htmlFor="aFinDate">Actual Finish Date</label>
                                                    <input type="date" name="aFinDate" value={projectData.dailyWorkRecords[key].aFinDate} onChange={e => handleWork(key, e)} />
                                                </div>
                                            </div>

                                            <label htmlFor="lotNo">Lot No.</label>
                                            <input type="text" name="lotNo" value={projectData.dailyWorkRecords[key].lotNo} onChange={e => handleWork(key, e)} />

                                            <label>HP/WP Release</label>
                                            <select name="hpwpRelease" defaultValue={projectData.dailyWorkRecords[key].hpwpRelease} onChange={e => handleWork(key, e)}>
                                                <option value="Y">Y</option>
                                                <option value="N">N</option>
                                                <option value="NA">NA</option>
                                            </select>

                                            <label htmlFor="dwrDesc">Description, Location, Output/Work Completed</label>
                                            <textarea cols="2" name="dwrDesc" value={projectData.dwrDesc} onChange={e => handleWork(key, e)} />

                                            <h5>Plant/Equipment</h5>
                                            {
                                                Object.keys(projectData.dailyWorkRecords[key].equipment).map((subkey, index) => (

                                                    <fieldset className="form-flex" key={subkey}>
                                                        <button type="button" className="delBtn" onClick={() => delPlant(key, index)}>❌</button>
                                                        <label>Plant/Equipment</label>
                                                        <input type="text" name="plant" value={projectData.dailyWorkRecords[key].equipment[subkey].plant} onChange={e => handlePlant(key, e, index)} />

                                                        <label>No.</label>
                                                        <input type="text" name="no" value={projectData.dailyWorkRecords[key].equipment[subkey].no} onChange={e => handlePlant(key, e, index)} />

                                                        <label>Hours</label>
                                                        <input type="text" name="hrs" value={projectData.dailyWorkRecords[key].equipment[subkey].hrs} onChange={e => handlePlant(key, e, index)} />

                                                    </fieldset>
                                                ))
                                            }
                                            <button type="button" onClick={() => addPlant(key)}>Add Plant/Equipment</button>

                                            <h5>Personnel</h5>
                                            {
                                                Object.keys(projectData.dailyWorkRecords[key].personnel).map((subkey, index) => (

                                                    <fieldset className="form-flex" key={subkey}>
                                                        <button type="button" className="delBtn" onClick={() => delPersnl(key, index)}>❌</button>
                                                        <label>Description</label>
                                                        <input type="text" name="desc" value={projectData.dailyWorkRecords[key].personnel[subkey].desc} onChange={e => handlePersnl(key, e, index)} />

                                                        <label>No.</label>
                                                        <input type="text" name="no" value={projectData.dailyWorkRecords[key].personnel[subkey].no} onChange={e => handlePersnl(key, e, index)} />

                                                        <label>Hours</label>
                                                        <input type="text" name="hrs" value={projectData.dailyWorkRecords[key].personnel[subkey].hrs} onChange={e => handlePersnl(key, e, index)} />

                                                    </fieldset>
                                                ))
                                            }
                                            <button type="button" onClick={() => addPersnl(key)}>Add Personnel</button>

                                            <label htmlFor="imgLoc">Image Location/Chainage</label>
                                            <input type="text" name="imgLoc" value={projectData.dailyWorkRecords[key].imgLoc} onChange={e => handleWork(key, e)} />

                                            <input type="file" name="img" onChange={e => handleImg(key, e)} />

                                            <img src={projectData.dailyWorkRecords[key].img} alt="" style={{ width: "250px" }} />
                                        </div>
                                    ))
                                }

                                <button type="button" onClick={() => addWork()}>Add Work Record</button>
                            </div>
                        </section>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd6" name="rd6" />
                            <label className="tab-label" htmlFor="rd6"><h3>Material Deliveries</h3></label>

                            <div className="tab-content">
                                {
                                    // build an input for every visit
                                    // "key" are properties projectData.visits, e.g. "visit_1"
                                    Object.keys(projectData.mats).map((key, index) => (

                                        <fieldset className="form-flex" key={key}>
                                            <button type="button" className="delBtn" onClick={() => delMat(key)}>❌</button>
                                            <label>Material</label>
                                            <input type="text" name="material" value={projectData.mats[key].material} onChange={e => handleMat(key, e)} />

                                            <label>Supplier</label>
                                            <input type="text" name="supplier" value={projectData.mats[key].supplier} onChange={e => handleMat(key, e)} />

                                            <label>Description</label>
                                            <input type="text" name="desc" value={projectData.mats[key].desc} onChange={e => handleMat(key, e)} />

                                            <label>Quantity</label>
                                            <input type="text" name="quantity" value={projectData.mats[key].quantity} onChange={e => handleMat(key, e)} />

                                            <label>Defect Notes & Waste Quantity</label>
                                            <input type="text" name="defects" value={projectData.mats[key].defects} onChange={e => handleMat(key, e)} />
                                        </fieldset>
                                    ))
                                }

                                <button type="button" onClick={() => addMat()}>Add Material</button>
                            </div>
                        </section>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd7" name="rd7" />
                            <label className="tab-label" htmlFor="rd7"><h3>Generic/System Issues</h3></label>

                            <div className="tab-content">

                                {issuesDisp.map((issue, index) =>
                                    <div className="form-flex" key={issue}>
                                        <h4>{issue}</h4>

                                        <div className="form-flex-alt-spaceBet">
                                            <label>Issue? Yes/No</label>
                                            <input type="checkbox" name="issue" checked={issues_rows[index].issue} onChange={e => handleIssue(e, issuesFunc[index])} />
                                        </div>

                                        <label>FAR Number</label>
                                        <input type="text" name="farNo" value={issues_rows[index].farNo} onChange={e => handleIssue(e, issuesFunc[index])} />

                                        <label>Number of Services</label>
                                        <input type="text" name="noServices" value={issues_rows[index].noServices} onChange={e => handleIssue(e, issuesFunc[index])} />

                                        <label>Issues Description/Item Comments (both positive and negative)</label>
                                        <input type="text" name="desc" value={issues_rows[index].desc} onChange={e => handleIssue(e, issuesFunc[index])} />

                                    </div>
                                )}
                            </div>
                        </section>

                        <section className="form-flex tab">
                            <input type="checkbox" className="tab-checkbox" id="rd8" name="rd8" />
                            <label className="tab-label" htmlFor="rd8"><h3>General Comments</h3></label>

                            <div className="tab-content">
                                <textarea cols="2" name="genComm" value={projectData.genComm} onChange={e => handleChange(e)} />
                            </div>
                        </section>

                        <section className="form-flex">
                            <button type="button" onClick={() => exportPDF()}>Generate PDF</button>
                        </section>
                    </form>
                </aside>
            </section>
        </div >
    );
}
