import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/about";
import Projects from "../pages/projects";
import Services from "../pages/services";
import Contact from "../pages/contact";
import Report from "../pages/report";

export default function Nav() {
    return (
        <BrowserRouter>
            <nav className="nav">
                <Link to="/" className="site-title">
                    <img src={require("../assets/mfc-engineering-logo.png")} alt="" />
                </Link>

                <ul className="nav-list">
                    <li>
                        <Link to="/" className="nav-link">Home</Link>
                    </li>

                    <li>
                        <Link to="/about" className="nav-link">About</Link>
                    </li>

                    <li>
                        <Link to="/projects" className="nav-link">Projects</Link>
                    </li>

                    <li>
                        <Link to="/services" className="nav-link">Services</Link>
                    </li>

                    <li>
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </li>
                </ul>
            </nav >

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/report" element={<Report />} />
            </Routes>
        </BrowserRouter >

    );
}