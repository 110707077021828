export default function Footer() {
    return (
        <footer>
            <div className="site-footer">MFC Engineering Service PTY LTD</div>

            <ul className="footer-contact-list">
                <li>Mobile: <a href="tel:0401-404-565">0401 404 565</a> |</li>
                <li>Email: <a href="mailto:michael@mfc.engineering">michael@mfc.engineering</a> |</li>
                <li>LinkedIn: <a href="https://www.linkedin.com/in/mikelcruz/">mikelCruz</a></li>
            </ul>
        </footer>
    );
}