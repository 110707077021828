export default function About() {
    return (
        <div className="about-page">
            <section className="img-banner-alt align-left about-page-banner">
                <aside className="section-card">
                    <h2>About MFC</h2>
                    <p> Who we are and what we strive to deliver.
                    </p>
                </aside>
            </section>

            <section className="default-section">
                <aside className="section-entry">
                    <p>
                        MFC Engineering Service is a newly established civil engineering consulting company created by Michael Cruz to
                        provide excellent project management, civil engineering solutions. <br /><br />

                        Based in North Lakes, Queensland, Michael is highly skilled within the civil engineering industry. Having worked
                        in Australia and overseas for local government and multinational companies, he is able to draw on his decades
                        worth of experience in highways, rail, bridge, LNG materials testing, quality auditing and bulk earthworks to
                        provide practical advice and optimum solutions. <br /><br />

                        Michael holds a Bachelor of Applied Science (B.A.Sc.) focused on Civil Engineering from the Polytechnic
                        University of the Philippines with a background in strong construction and project management. <br /><br />
                        Through an effective and sensible approach, we can ensure that clients are given practical and cost-effective
                        solutions within a timely manner.
                    </p>
                </aside>

                <img src={require("../assets/latest-project01.jpg")} alt="" className="img-round_corners section-img" />
            </section>

        </div >
    );
}