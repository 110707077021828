import { Link } from "react-router-dom";

export default function Services() {

    return (
        <div className="services-page">
            <section className="img-banner-alt align-left services-page-banner">
                <aside className="section-card">
                    <h2>Services</h2>
                    <p>
                        MFC Engineering Service are experts in Civil Engineering.
                        We can provide the expertise needed for your next project.
                    </p>
                </aside>
            </section>

            <section className="center-section">
                <ul className="card-list">
                    <li>
                        <img src={require("../assets/1613766849625.jpg")} alt="" className="service-img" />
                        <h3>Bridge & Highways</h3>
                    </li>

                    <li>
                        <img src={require("../assets/1613768422810.jpg")} alt="" className="service-img" />
                        <h3>Rail</h3>
                    </li>

                    <li>
                        <img src={require("../assets/1613767082032.jpg")} alt="" className="service-img" />
                        <h3>LNG</h3>
                    </li>

                    <li>
                        <img src={require("../assets/pipes-sorted-on-construction-site.jpg")} alt="" className="service-img" />
                        <h3>Materials Testing</h3>
                    </li>

                    <li>
                        <img src={require("../assets/site-audit.jpg")} alt="" className="service-img" />
                        <h3>Quality Auditing</h3>
                    </li>

                    <li>
                        <img src={require("../assets/1613769093620.jpg")} alt="" className="service-img" />
                        <h3>Bulk Earthworks</h3>
                    </li>

                </ul>
            </section>

            <section className="img-banner-alt align-right services-page-contact-banner">
                <aside className="section-card contact-card">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-mobile-message card-icon"
                        width="50" height="50" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M11 3h10v8h-3l-4 2v-2h-3z"></path>
                        <path d="M15 16v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h2"></path>
                        <path d="M10 18v.01"></path>
                    </svg>

                    <p>
                        Call us to discuss your engineering needs at <a href="tel:0401-404-565">0401 404 565</a>. <br />
                        Or send us an email at <a href="mailto:michael@mfc.engineering">michael@mfc.engineering</a>.
                    </p>

                    <Link to="/Contact" className="nav-btn">Contact Us</Link>
                </aside>
            </section>

        </div>
    );
}