export default function Contact() {
    return (
        <div className="contact-page">
            <section className="img-banner-alt align-left contact-page-banner">
                <aside className="section-card">
                    <h2>Contact Us</h2>
                    <p>
                        We'd love to hear from you.
                    </p>
                </aside>
            </section>

            <section className="default-section">
                <img src={require("../assets/andrew-neel-cckf4TsHAuw-unsplash.jpg")} alt="" className="img-round_corners section-img" />
                <aside className="section-entry">
                    <h3>Mobile</h3>
                    <a href="tel:0401-404-565">0401 404 565</a>

                    <h3>Email</h3>
                    <a href="mailto:michael@mfc.engineering">michael@mfc.engineering</a>

                    <h3>LinkedIn</h3>
                    <a href="https://www.linkedin.com/in/mikelcruz/">mikelcruz</a>

                    <h3>Operation Hours:</h3>
                    <h4>Monday - Friday</h4>
                    <p>7am - 5pm</p>

                    <h4>Saturday - Sunday</h4>
                    <p>If Required</p>
                </aside>

            </section>
        </div>
    );
}