import projects from "../assets/projects.json";

export default function Projects() {

    return (
        <div className="projects-page">
            <section className="img-banner-alt align-left projects-page-banner">
                <aside className="section-card">
                    <h2>Projects</h2>
                    <p>
                        MFC Engineering Service has successfully completed a range of civil engineering projects in Australia and overseas.
                    </p>
                </aside>
            </section>

            <section className="center-section">
                <ul className="card-list">
                    {projects.map((data, key) => {
                        return (
                            <li key={data.id}>
                                <img src={require('../assets/' + data.fileName)} className="service-img" alt="" />
                                <h3>{data.projectName}</h3>
                                <p>{data.startDate} - {data.endDate}</p>
                            </li>
                        );
                    })}
                </ul>
            </section>
        </div>
    );
}